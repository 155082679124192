import environment from 'environment';

export class AppService {

  async userLogin(user) {
    const response = await fetch(`${environment.api}/login`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(user)
    })
    return await response.json();
  }

  async addUser(user) {
    const response = await fetch(`${environment.api}/users`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(user)
    })
    return await response.json();
  }

  async sendEmail(user) {
    const response = await fetch(`${environment.api}/password/forgot-password`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(user)
    })
    return await response.json();
  }

  async deleteAccount(user) {
    const response = await fetch(`${environment.api}/password/delete-account`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(user)
    })
    return await response.json();
  }

  async verifyOTP(user) {
    const response = await fetch(`${environment.api}/password/verify-otp`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(user)
    })
    return await response.json();
  }

  async changePassword(user) {
    const response = await fetch(`${environment.api}/password/confirm-password`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(user)
    })
    return await response.json();
  }

  async createPost(user) {
    const token = localStorage.getItem('userDetails');
    const response = await fetch(`${environment.api}/post`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(user)
    })
    return await response.json();
  }

}