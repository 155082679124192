import './../common.scss';

const Header = () => {
    return (
        <header className="basic-header" onClick={() => {window.location.href = '/'}}>
            <img src="../assets/images/icon/logo.svg" alt="logo" className="img-fluid blur-up lazyload" width={300} />
        </header>
    )
}

export default Header;