const preferenceService = {
    get: (key, defaultValue) => {
        return JSON.parse(localStorage.getItem(key) || (defaultValue || '{}'));
    },
    currentUser: () => {
        return JSON.parse(localStorage.getItem('userDetails') || '{}');
    },
    isLive: () => {
        return !!JSON.parse(localStorage.getItem('userDetails') || '{}').token;
    },
    set: (data, key) => {
        localStorage.setItem(key || 'userDetails', JSON.stringify(data));
    },
    token: () => {
        return preferenceService.currentUser().token
    },
    screenView: () => {
        return JSON.parse(localStorage.getItem('mobileView') || false);
    },
    getServerTime: () => {
        var xmlHttp;
        try {
            //FF, Opera, Safari, Chrome
            xmlHttp = new XMLHttpRequest();
        }
        catch (err1) {

            //AJAX not supported, use CPU time.
            alert("AJAX not supported");

        }
        xmlHttp.open('HEAD', '/timer', false);
        xmlHttp.setRequestHeader("Content-Type", "text/html");
        xmlHttp.send('');
        return xmlHttp.getResponseHeader("Date");
    },
    clearLocal: () => {
        const screenView = preferenceService.screenView();
        localStorage.clear();
        localStorage.setItem('mobileView', screenView);
    }
}

export {
    preferenceService
}
