import environment from 'environment';

const AccountService = {
    ACCOUNT_LOGIN: () => {
        if (environment.isMock) {
            return 'mock/account/login.json';
        }
        return `${environment.api}/login`;
    },
    ACCOUNT_REGISTER: () => {
        if (environment.isMock) {
            return 'mock/account/register.json';
        }
        return `${environment.api}/login`;
    },
    SECURITY_QUESTION: () => {
        if (environment.isMock) {
            return 'mock/account/securityquestion.json';
        }
        return `${environment.api}/account/securityquestion`;
    },
    CHECK_FORGOT_PASSWORD: () => {
        if (environment.isMock) {
            return 'mock/forgotPassword/success.json';
        }
        return `${environment.api}/user/forgotpassword`;
    },
    CHECK_CONFIRM_PASSWORD: () => {
        if (environment.isMock) {
            return 'mock/forgotPassword/success.json';
        }
        return `${environment.api}/user/resetPassword`;
    },
    UPDATE_PASSWORD: () => {
        if (environment.isMock) {
            return 'mock/forgotPassword/success.json';
        }
        return `${environment.api}/users/change-password`;
    },
    GET_REFUND: () => {
        if (environment.isMock) {
            return 'mock/forgotPassword/success.json';
        }
        return `${environment.api}/admin/refund`;
    },
    UPDATE_REFUND_STATUS: () => {
        if (environment.isMock) {
            return 'mock/forgotPassword/success.json';
        }
        return `${environment.api}/admin/refundStatus`;
    },
    GET_PROFILTLOSS: () => {
        if (environment.isMock) {
            return 'mock/forgotPassword/success.json';
        }
        return `${environment.api}/admin/profitloss`;
    },
    GET_ADMIN_AGENTPL: () => {
        if (environment.isMock) {
            return 'mock/forgotPassword/success.json';
        }
        return `${environment.api}/admin/agentsprofitloss`;
    },
    GET_ADMIN_USERPL: () => {
        if (environment.isMock) {
            return 'mock/forgotPassword/success.json';
        }
        return `${environment.api}/admin/usersprofitloss`;
    },
    CLEAR_PROFITLOSS: () => {
        if (environment.isMock) {
            return 'mock/forgotPassword/success.json';
        }
        return `${environment.api}/admin/clearprofitloss`;
    },
    ADMIN_PROFIT_LOSS: () => {
        if (environment.isMock) {
            return 'mock/forgotPassword/success.json';
        }
        return `${environment.api}/admin/currentMonthProfitLoss`;
    },
    ADMIN_TODAY_PROFIT_LOSS: () => {
        if (environment.isMock) {
            return 'mock/forgotPassword/success.json';
        }
        return `${environment.api}/admin/currentDayProfitLoss`;
    },
    STATUS: (id) => {
        if (environment.isMock) {
            return 'mock/forgotPassword/success.json';
        }
        return `${environment.api}/users/status/${id}`;
    },
    USER: (id) => {
        if (environment.isMock) {
            return 'mock/forgotPassword/success.json';
        }
        if (id) {
            return `${environment.api}/users/${id}`;
        }
        return `${environment.api}/users`;
    },
    UPDATE_USER: (id) => {
        if (environment.isMock) {
            return 'mock/forgotPassword/success.json';
        }
        if (id) {
            return `${environment.api}/users/update/${id}`;
        }
        return `${environment.api}/users`;
    },
    VERIFY_OTP: () => {
        return `${environment.api}/users/verify-otp`;
    },
    WARRIOR: () => {
        if (environment.isMock) {
            return 'mock/forgotPassword/success.json';
        }
        return `${environment.api}/users/warrior`;
    },
    USER_IMAGE: (id) => {
        if (environment.isMock) {
            return 'mock/forgotPassword/success.json';
        }
        if (id) {
            return `${environment.api}/users/image/${id}`;
        }
        return `${environment.api}/users`;
    },
    COUNTRY: () => {
        return `${environment.api}/country`;
    },
    STATE: (name) => {
        return `${environment.api}/state/${name}`;
    },
    CITY: (name) => {
        return `${environment.api}/city/${name}`;
    },
    UPDATE_FRESH_USER: (id) => {
        return `${environment.api}/users/freshUser/${id}`;
    },
}

export default AccountService;