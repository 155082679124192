import axios from "axios"
import AccountService from "../api/account-service"
import { preferenceService } from "../preference-service"

const getStatus = (refresh) => {
    const id = preferenceService.currentUser().id;
    axios.get(AccountService.STATUS(id)).then(({data}) => {
        preferenceService.set(data.result.favorites, 'favorites')
        preferenceService.set(data.result.follows, 'follows')
        if (refresh) {
            refresh();
        }
    });
}

export {
    getStatus
}