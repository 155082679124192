// src/environments/production.ts
import environment from './base';

const baseApi = 'https://server.salvationlamb.com/api/v1';
const env = environment(baseApi);
const data = {
  ...env,
  isMock: false,
}

export default data;