import React, { useEffect, useState } from 'react';
import Form, { ErrorMessage } from '@atlaskit/form';
import { withRouter } from 'react-router-dom';
import { AppService } from '../../service/api/api-service';
import { toast } from 'react-toastify';
import Header from '../common/basic-header/Header';

const ForgotPassword = ({ history }) => {

    const [userEmail, setEmail] = useState('');
    const [userOTP, setOTP] = useState('');
    const [userPassword, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showOTP, setShowOTP] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isBtnDisable, setBtnDis] = useState(false);
    const [showEmailForm, setShowEmailForm] = useState(true);
    const [showPswForm, setShowPswForm] = useState(false);
    const [oldPassShow, setOldShowPassword] = useState(false);
    const [newPasswordShow, setNewShowPassword] = useState(false);
    const appService = new AppService();

    useEffect(() => {
        window.feather?.replace();
    }, []);

    // Generate JSX code for error message
    const renderErrorMessage = (name) =>
        name === errorMessage.name && (
            <ErrorMessage>{errorMessage.message}</ErrorMessage>
        );

    // form submit
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        let userObj = {};
        if (showEmailForm && !showOTP) {
            userObj = { email: userEmail };

            const response = await appService.sendEmail(userObj)
            if (response && response.status === 200) {
                toast.success(response.message);
                setShowOTP(true);
            } else {
                toast.error(response.errorMessage);
            }
            setLoading(false);
        } else if (showOTP) {

            userObj = { email: userEmail, otp: userOTP }
            const response = await appService.verifyOTP(userObj)
            if (response && response.status === 200) {
                toast.success(response.message);
                setShowEmailForm(false);
                setShowOTP(false);
                setShowPswForm(true);
            } else {
                toast.error(response.errorMessage);
            }
            setLoading(false);
        } else {
            userObj = { email: userEmail, otp: userOTP, password: userPassword }

            const response = await appService.changePassword(userObj)
            if (response && response.status === 200) {
                toast.success(response.message);
                history.push('login');
            } else {
                toast.error(response.errorMessage);
            }
            setLoading(false);
        }
    };

    const checkPassword = (password) => {
        setConfirmPassword(password);
        if (password && userPassword && userPassword !== password) {
            setBtnDis(true);
            setErrorMessage({
                message: 'Password mismatch !',
                name: 'confirmPassword'
            })
        } else {
            setBtnDis(!(password && userPassword));
            setErrorMessage({})
        }
    }

    return (
        <div>
            <Header />
            <section class="login-section">
                <div class="header-section">
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-6 col-lg-5 d-none d-lg-block">
                            <div class="login-welcome">
                                <div>
                                    {/* <img src="../assets/images/login/charcter.png" class="img-fluid blur-up lazyload"
                                    alt="charcter" /> */}
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-7 col-md-10 col-12 m-auto">
                            <div class="login-form">
                                <div>
                                    <div class="login-title">
                                        <h2>{showEmailForm ? 'Forgot Password' : 'Change Password'}</h2>
                                    </div>
                                    <div class="login-discription">
                                        <h3>Hello</h3>
                                        <h4>Welcome to SalvationLamb, please enter your <b>{showEmailForm ? 'Email id' : 'New password'}</b>.
                                        </h4>
                                    </div>
                                    {showEmailForm && (
                                        <div class="form-sec">
                                            <div>
                                                <form class="theme-form" onSubmit={(e) => handleSubmit(e)}>
                                                    {!showOTP && (
                                                        <div class="form-group">
                                                            <label for="exampleInputEmail1">Email address</label>
                                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                                                placeholder="Enter email" value={userEmail} onChange={(e) => setEmail(e.target.value)} required />
                                                            <i class="input-icon iw-20 ih-20" data-feather="user"></i>
                                                            {renderErrorMessage("email")}
                                                        </div>
                                                    )}
                                                    {showOTP && (
                                                        <div class="form-group">
                                                            <label for="exampleInputPassword1">Enter OTP</label>
                                                            <input type="text" class="form-control" id="exampleInputPassword1"
                                                                placeholder="OTP" value={userOTP} onChange={(e) => setOTP(e.target.value)} required />
                                                        </div>
                                                    )}

                                                    <div class="bottom-sec">
                                                        <a href="javascript:void(0)" onClick={() => {
                                                            history.push('login')
                                                        }} className="ms-auto h3 forget">Login?</a>
                                                    </div>
                                                    <div class="btn-section">
                                                        <button type="submit" disabled={isLoading} class="btn btn-solid btn-lg">Submit</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    )}
                                    {showPswForm && (
                                        <div class="form-sec">
                                            <div>
                                                <form class="theme-form" onSubmit={(e) => handleSubmit(e)}>
                                                    <div class="form-group">
                                                        <label for="exampleInputPassword1">Password</label>
                                                        <input type={oldPassShow ? 'text' : "password"} class="form-control" id="exampleInputPassword1"
                                                            placeholder="Password" value={userPassword} onChange={(e) => setPassword(e.target.value)} required />
                                                        {renderErrorMessage("password")}
                                                        <span style={{ opacity: !oldPassShow ? 1 : 0 }} onClick={() => {
                                                            setOldShowPassword(!oldPassShow)
                                                        }}>
                                                            <i class="input-icon iw-20 ih-20" data-feather="eye"></i>
                                                        </span>
                                                        <span style={{ opacity: oldPassShow ? 1 : 0 }} onClick={() => {
                                                            setOldShowPassword(!oldPassShow)
                                                        }}>
                                                            <i class="input-icon iw-20 ih-20" data-feather="eye-off"></i>
                                                        </span>
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="exampleInputPassword1">Confirm Password</label>
                                                        <input type={newPasswordShow ? 'text' : "password"} class="form-control" id="exampleInputPassword1"
                                                            placeholder="Password" value={confirmPassword} onChange={(e) => checkPassword(e.target.value)} required />
                                                        <span style={{ opacity: !newPasswordShow ? 1 : 0 }} onClick={() => {
                                                            setNewShowPassword(!newPasswordShow)
                                                        }}>
                                                            <i class="input-icon iw-20 ih-20" data-feather="eye"></i>
                                                        </span>
                                                        <span style={{ opacity: newPasswordShow ? 1 : 0 }} onClick={() => {
                                                            setNewShowPassword(!newPasswordShow)
                                                        }}>
                                                            <i class="input-icon iw-20 ih-20" data-feather="eye-off"></i>
                                                        </span>
                                                    </div>
                                                    {renderErrorMessage("confirmPassword")}

                                                    <div class="btn-section">
                                                        <button type="submit" disabled={isBtnDisable} class="btn btn-solid btn-lg">Submit</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div >
    )
}

export default withRouter(ForgotPassword);
