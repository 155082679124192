import React, { useEffect, useState } from 'react';
import { ErrorMessage } from '@atlaskit/form';
import { withRouter } from 'react-router-dom';
import { AppService } from '../../service/api/api-service';
import { toast } from 'react-toastify';
import Header from '../common/basic-header/Header';
import './deletaccount.scss';

const DeleteAccount = ({ history }) => {

    const [userEmail, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [name, setName] = useState('');
    const [userOTP, setOTP] = useState('');
    const [userPassword, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showOTP, setShowOTP] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [showEmailForm, setShowEmailForm] = useState(true);
    const appService = new AppService();

    useEffect(() => {
        window.feather?.replace();
    }, []);

    // Generate JSX code for error message
    const renderErrorMessage = (name) =>
        name === errorMessage.name && (
            <ErrorMessage>{errorMessage.message}</ErrorMessage>
        );

    // form submit
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        let userObj = {};
        if (showEmailForm && !showOTP) {
            userObj = { email: userEmail, mobile };

            const response = await appService.deleteAccount(userObj)
            if (response && response.status === 200) {
                toast.success(response.message);
                setShowOTP(true);
            } else {
                toast.error(response.errorMessage);
            }
            setLoading(false);
        }
    };

    return (
        <div>
            <Header />
            <section class="login-section">
                <div class="header-section">
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-6 col-lg-5 d-none d-lg-block">
                            <div class="login-welcome">
                                <div>
                                    {/* <img src="../assets/images/login/charcter.png" class="img-fluid blur-up lazyload"
                                    alt="charcter" /> */}
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-7 col-md-10 col-12 m-auto">
                            <div class="login-form">
                                <div>
                                    <div class="login-title">
                                        <h2>Delete Account Request</h2>
                                    </div>
                                    {
                                    !showOTP && <div class="login-discription">
                                        <h3>Note:</h3>
                                        <h4>Fill All the valid data which is used in our app</h4>
                                    </div>
                                    }
                                    {showEmailForm && (
                                        <div class="form-sec">
                                            <div>
                                                <form class="theme-form" onSubmit={(e) => handleSubmit(e)}>
                                                    {!showOTP && (
                                                        <>
                                                        <div class="form-group">
                                                            <label for="exampleInputEmail1">Name</label>
                                                            <input type="text" class="form-control" id="exampleInputEmail1"
                                                                placeholder="Full Name" value={name} onChange={(e) => setName(e.target.value)} required />
                                                            <i class="input-icon iw-20 ih-20" data-feather="user"></i>
                                                            {renderErrorMessage("name")}
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="exampleInputEmail1">Email address</label>
                                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                                                placeholder="Enter email" value={userEmail} onChange={(e) => setEmail(e.target.value)} required />
                                                            <i class="input-icon iw-20 ih-20" data-feather="mail"></i>
                                                            {renderErrorMessage("email")}
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="exampleInputEmail1">Mobile No</label>
                                                            <input type="number" class="form-control" id="mobileNumber"
                                                                placeholder="Enter Mobile" value={mobile} onChange={(e) => setMobile(e.target.value)} required />
                                                            <i class="input-icon iw-20 ih-20" data-feather="phone"></i>
                                                            {renderErrorMessage("mobile")}
                                                        </div>
                                                        <div class="bottom-sec">
                                                        <a href="javascript:void(0)" onClick={() => {
                                                            history.push('login')
                                                        }} className="ms-auto h3 forget">Back to Login?</a>
                                                    </div>
                                                    <div class="btn-section">
                                                        <button type="submit" disabled={isLoading} class="btn btn-solid btn-lg">Submit</button>
                                                    </div>
                                                        </>
                                                    )}
                                                    {
                                                        showOTP && <>
                                                        <div className='w-100 text-center mt-5'>
                                                            <h2>Your Accout was Deleted Successfully</h2>
                                                            <button className='btn btn-primary btn-lg mt-4' onClick={() => {
                                                                window.location.href = '/';
                                                            }}>Home</button>
                                                        </div>
                                                        </>
                                                    }

                                                   
                                                </form>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div >
    )
}

export default withRouter(DeleteAccount);
